$accent-color: #c83649;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Montserrat", sans-serif;
  background-color: #f4f4f4;
  width: 100vw;
  overflow-x: hidden;
}

.navbar {
  position: sticky;
  top: 0rem;
  background-color: transparent;
  height: 7rem;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0rem 5rem;
  justify-content: space-between;
  z-index: 9999;
  transition: all 0.5s ease-in-out;

  &.not-home {
    background-color: #f8f9fa;
    .logo {
      span {
        a {
          color: #000000;
        }
      }
    }

    .menu {
      .line {
        background-color: #000;
        color: #000;
        z-index: 9999999;
      }
    }

    .mid-links {
      a {
        color: #000;
      }
    }

    .link {
      a {
        color: #000;
      }
    }

    /* Change this to the color you want */
  }

  &.scrolled {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.986);

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);

    height: 5rem;
    z-index: 9999;

    .logo {
      span {
        a {
          color: rgb(0, 0, 0);
        }
      }
    }

    .menu {
      .line {
        background-color: black;
        color: #000;
        z-index: 9999999;
      }
    }

    .mid-links {
      a {
        color: black;
      }
    }

    .link {
      a {
        color: black;
      }
    }
  }

  &.show {
    transition: all 0.5s ease-in-out;

    .logo {
      span {
        a {
          color: rgb(255, 255, 255);
        }
      }
    }

    .menu {
      .line {
        background-color: black;
        color: #000;
        z-index: 9999999;
      }
    }
  }

  .logo {
    width: 15%;

    span {
      font-size: 2rem;
      font-weight: bold;
      a {
        text-decoration: none;
        color: white;
      }
    }
  }

  .mid-links {
    display: flex;
    width: 70%;
    justify-content: center;
    align-items: center;
    list-style: none;
    width: 50%;
    ul {
      list-style: none;
      display: flex;
      gap: 3rem;
    }
    a {
      color: #f4f4f4;
      text-decoration: none;
      font-weight: 600;
    }
    .active-link {
      color: #c83649;
    }
  }

  .menu {
    justify-content: space-around;
    width: 2.6rem;
    height: 2rem;
    display: none;
    flex-direction: column;
    justify-content: space-between;

    .line {
      width: 100%;
      height: 2px;
      border-radius: 1px;
      background-color: rgb(255, 255, 255);
      transform-origin: left center;

      transition: all 0.5s ease-in-out;
    }

    &.show {
      .line {
        color: #000;
      }

      .line1 {
        rotate: 45deg;
        color: #000;
      }

      .linemid {
        opacity: 0;
        color: #000;
      }

      .line2 {
        rotate: -45deg;
        color: #000;
      }
    }
  }

  .link {
    width: 15%;
    display: flex;
    justify-content: flex-end;

    a {
      color: #f4f4f4;
      text-decoration: none;
      font-weight: bold;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 0rem 2rem;

    .link {
      display: none;
    }

    .mid-links {
      display: none;
    }

    .menu {
      display: flex;
    }
  }
}
.reveal-navbar {
  background-color: #f4f4f4;
  transition: 0.5s linear;
  width: 100vw;
  height: 70vh;
  padding-top: 4rem;
  position: fixed;
  z-index: 99;
  top: 0rem;
  transform: translateY(-100vh);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0rem 0rem 5rem 5rem;
  display: none;

  ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    text-align: center;
    gap: 2rem;
    li {
      a {
        color: #000;
        font-size: 3rem;
        text-decoration: none;

        &::after {
          content: "";
          display: block;
          width: 0;
          height: 3px;
          background: #000;
          transition: width 0.3s;
        }

        &:hover::after {
          width: 100%;
          transition: width 0.3s;
        }

        &:hover {
          color: #585858;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    display: flex;
  }
}

.reveal-navbar.show {
  transform: translateY(0);
}
.hero-div-main {
  margin-top: -7rem;
  height: 105vh;
  width: 100%;
  background-size: cover;
  display: flex;
  z-index: 1;
  flex-direction: column;
  justify-content: flex-end;
  background-image: url(../Images/mainbg_cmp.jpg);
  background-size: cover;

  .hero-text {
    width: 50%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2rem;
    z-index: 999;

    .title {
      color: #f4f4f4;
      font-size: 8rem;
      line-height: 0.9;
    }
  }

  @media screen and (max-width: 768px) {
    background-image: url(https://e1.pxfuel.com/desktop-wallpaper/1012/231/desktop-wallpaper-ana-de-armas-for-vogue-spain-ultra-mobile-ana-de-armas.jpg);

    .hero-text {
      width: 100%;
      padding: 1rem;
      text-align: left;
      justify-content: flex-end;
      transform: translateY(-7rem);
      .title {
        font-size: 3.5rem;
      }
    }
  }
}

.intro-div-main {
  z-index: 1000;
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  border-radius: 10rem 10rem 0rem 0rem;
  transform: translateY(-7rem);
  background-color: #efe3db;

  .special_div_main {
    width: 100vw;

    display: flex;
    flex-direction: column;
    padding: 2rem;
    position: relative;
    overflow: hidden;
    align-items: center;

    .title {
      font-size: 2.5rem;
      font-weight: bold;
      text-align: center;
    }

    .cards {
      position: relative;
      border-radius: 5rem;
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      background-color: #ffffff72;
      padding: 2rem;

      .card {
        flex: 1;
        position: relative;

        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .image {
          width: 60%;
          height: 100%;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            mix-blend-mode: multiply;
          }
        }

        .text {
          height: 100%;

          display: flex;
          text-align: center;

          .main {
            font-size: 1.3rem;
          }

          .main1 {
            align-self: start;
            margin-top: 2rem;

            .line2 {
              display: block;
              font-weight: bold;
              color: #c83649;
            }
          }
          .main2 {
            align-self: center;

            .line2 {
              display: block;
              font-weight: bold;
              color: #c83649;
            }
          }
          .main3 {
            align-self: end;
            margin-bottom: 2rem;

            .line2 {
              display: block;
              font-weight: bold;
              color: #c83649;
            }
          }
        }
      }
    }

    @media screen and (max-width: 768px) {
      padding: 2rem;
      .cards {
        display: flex;
        flex-direction: column;
        border-radius: 2rem;
        padding: 1rem;
        gap: 1rem;
        .card {
          align-items: center;
          .image {
            height: 70%;
          }
          .text {
            height: 30%;
          }
        }
      }
    }
  }

  .title {
    padding: 5rem;
    font-size: 2rem;
    width: 70%;
    text-align: center;
  }

  .sr-only:not(:focus):not(:active) {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  .cards {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    gap: 3rem;

    .card {
      width: 100%;
      display: flex;
      padding: 1rem;
      align-items: center;
      justify-content: center;

      &.inverted {
        flex-direction: row-reverse;
      }

      .image {
        width: 40%;
        height: 80%;
        border-radius: 5rem;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &:hover {
          img {
            transform: scale(1.02);
            transition: all 0.5s ease-in-out;
          }
        }
      }

      .content {
        width: 50%;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 10rem;
        gap: 1rem;

        h1 {
          font-size: 2.5rem;
          font-weight: bold;
        }

        span {
          font-size: 1.2rem;
          color: #5c5c5c;
        }
      }
    }
  }

  .courses-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    a {
      display: inline-flex;
      align-items: center;
      text-decoration: none;
      color: #ffffff;
      padding: 1rem;
      background-color: #000;
      margin-top: 1rem;
      border-radius: 0.5rem;
      justify-self: baseline;

      &:focus {
        outline: 1px dotted #28666e;
      }

      .icon {
        // min-width: 24px;
        // width: 24px;
        // height: 24px;
        // margin-left: 5px;
        // transform: translateX(var(--link-icon-translate));
        // opacity: var(--link-icon-opacity);
        // transition: all 0.3s;
        display: none;
      }
    }

    h1 {
      font-size: 2.5rem;
      font-weight: bold;
      text-align: center;
    }

    .cards {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 2rem;
      padding: 2rem;

      .card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        padding: 1rem;
        border-radius: 2rem;
        background-color: #ddd7d7;

        .image {
          width: 100%;
          height: 20rem;
          border-radius: 1.5rem;
        }

        .content {
          width: 100%;
          padding: 2rem;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          h2 {
            font-size: 2rem;
            font-weight: bold;
          }

          ul {
            list-style: none;
            display: flex;
            flex-direction: column;
            gap: 1rem;

            li {
              display: flex;
              gap: 1rem;
              align-items: center;
              justify-content: center;
              span {
                font-size: 1.5rem;
                color: #5c5c5c;
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: 768px) {
      padding: 1rem;
      .cards {
        display: flex;
        flex-direction: column;
        padding: 1rem;
        gap: 1rem;
        .card {
          align-items: center;
          background-color: rgb(235, 235, 235);
          .image {
            height: 70%;
          }
          .content {
            height: 30%;
            padding: 1rem;

            h2 {
              font-size: 1.5rem;
            }
          }
        }
      }
    }

    @media screen and (max-width: 768px) {
      .top {
        display: flex;
        flex-direction: column;
        .cards {
          display: flex;
          flex-direction: column;
          gap: 2rem;

          .card {
            border-radius: 2rem;
            display: flex;

            background-color: #ddd7d7;
            flex-direction: column;

            .image {
              width: 100%;
              height: 20rem;
              border-radius: 1.5rem;
            }

            .text {
              width: 100%;
              text-align: justify;
              .title {
                font-size: 2rem;
              }

              a {
                position: relative;
                bottom: 0rem;
                right: 0rem;
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    border-radius: 3rem 3rem 0rem 0rem;
    padding: 0rem;

    .title {
      padding: 1rem;
      padding-top: 3rem;
      font-size: 1.5rem;
      width: 100%;
    }

    .cards {
      display: flex;
      flex-direction: column;
      padding: 1rem;
      .card {
        display: flex;
        flex-direction: column;
        padding: 0rem;
        &.inverted {
          flex-direction: column;
        }
        .image {
          width: 100%;
          height: 20rem;
          border-radius: 2rem;
        }
        .content {
          width: 100%;
          padding: 1rem;
          text-align: justify;
          h1 {
            font-size: 2rem;
          }
        }
      }
    }
  }
}

.text-reveal-div {
  width: 100vw;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transform: translateY(-7rem);
  background-image: url(https://rare-gallery.com/uploads/posts/1158111-face-white-black-women-monochrome-model-portrait-long-hair-photography-black-hair-fashion-hair-nose-Person-head-supermodel-Matan-Eshel-Karin-Burmenko-girl-beauty-eye-wo.jpg);

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 20rem;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(239, 227, 219, 1) 100%
    );
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20rem;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(239, 227, 219, 1) 100%
    );
  }

  .line2 {
    transform: translateX(-100%);
  }

  h1 {
    color: #fff;
    font-weight: 300;
    mix-blend-mode: difference;
    font-size: 15rem;
    text-transform: uppercase;
    text-wrap: nowrap;
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    background-size: 250% 100%;
    background-position: -10rem;
    height: 100vh;

    h1 {
      font-size: 10rem;
    }
  }
}

.services-div-main {
  h1 {
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 2rem;
  }

  .cards {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2rem;

    .card {
      width: 100%;
      height: 40rem;
      display: flex;
      justify-content: flex-start;

      &.inverted {
        flex-direction: row-reverse;
      }

      .image {
        width: 50%;
        border-radius: 0rem;
        overflow: hidden;
        border-radius: 10rem;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &:hover {
          img {
            transform: scale(1.02);
            transition: all 0.5s ease-in-out;
          }
        }
      }

      .content {
        width: 50%;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 10rem;
        gap: 1rem;

        h1 {
          font-size: 2.5rem;
          font-weight: bold;
          text-align: left;
        }

        span {
          font-size: 1.2rem;
          color: #a0a0a0;
        }

        .link {
          text-decoration: none;
          color: #000;
          font-size: 1.5rem;
          font-weight: bold;
          transition: all 0.5s ease-in-out;

          &::after {
            content: "";
            display: block;
            width: 0;
            height: 3px;
            background: #000;
            transition: width 0.3s;
          }

          &:hover::after {
            width: 100%;
            transition: width 0.3s;
          }

          &:hover {
            color: #585858;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .cards {
      display: flex;
      flex-direction: column;
      padding: 1rem;
      .card {
        display: flex;
        flex-direction: column;
        padding: 0rem;
        &.inverted {
          flex-direction: column;
        }
        .image {
          width: 100%;
          height: 20rem;
          border-radius: 2rem;
        }
        .content {
          width: 100%;
          padding: 1rem;
          text-align: center;
          gap: 1rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          h1 {
            font-size: 2rem;
            text-align: center;
          }
        }
      }
    }
  }
}

.testimonials-div-main {
  margin-top: 6rem;

  width: 100%;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 2rem;
  }
  .testimonials {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 60vh;
    width: 80%;
    overflow: hidden;
    margin-bottom: 5rem;
    border-radius: 2rem;
    background-color: transparent;
    position: relative;

    .tst-bg {
      position: absolute;
      top: 1rem;
      width: 10%;
      left: 3rem;
      opacity: 0.3;
    }

    .tst-controls {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transform: translateY(-50%);
      z-index: 999;

      button {
        background-color: transparent;
        color: #000000;
        border: none;
        padding: 1rem 2rem;
        font-size: 1.5rem;
        cursor: pointer;
        transition: all 0.5s ease-in-out;
        border-radius: 2rem;
      }
    }

    .testimonial-item {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 2rem;

      transition: opacity 0.5s ease-in-out;

      opacity: 0;

      &.active {
        opacity: 1;

        padding: 1rem;
      }

      .cards {
        position: absolute;
        width: 90%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 2rem;
        height: 100%;
      }
      .card {
        flex: 1;
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: center;
        justify-content: center;
        background-color: #ece8e6;
        border-radius: 2rem;
        padding: 1rem;
        gap: 1rem;
        .profile_pic {
          position: relative;
          width: 50%;
          aspect-ratio: 1/1;
          overflow: hidden;
          border-radius: 50%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .tst-details {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 0rem;
          h1 {
            font-size: 2rem;
            font-weight: bold;
          }
          p {
            margin-top: -2rem;
            font-size: 1.2rem;
            text-align: center;
            width: 70%;
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    padding: 1rem;
    margin-top: 2rem;
    .testimonials {
      .testimonial-item {
        .cards {
          display: flex;
          flex-direction: column;
          gap: 10rem;

          .card {
            .profile_pic {
              width: 100%;
            }
            .tst-details {
              h1 {
                font-size: 1.5rem;
              }
              p {
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
  }
}

@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+Display:wght@300&family=Rubik:wght@400;500;700&display=swap");

$light: #ffffff;
$dark: #000;

.flex {
  display: flex;
}

.footer_video {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
  border: none;
}

.footer_inner {
  background: $light;
  backdrop-filter: blur(50px);
  border: 0.1px solid rgba(233, 232, 232, 0.208);
  border-radius: 0.5rem;
  padding: 2rem;
  margin: 1rem 0;
}

.footer {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 65vh;
  overflow: hidden;

  h3 {
    font-family: "Noto Serif Display", serif;
    font-size: 2.3rem;
    font-weight: 300;
    text-align: left;
    margin-bottom: 3rem;
  }

  a {
    margin-top: 1rem;
    text-decoration: none;
    color: $dark;
    font-weight: 500;
    transition: 0.3s ease-in;
    border-bottom: 1px solid transparent;
    margin-bottom: 0.5rem;
    display: inline-flex;

    &:hover {
      opacity: 0.7;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  button {
    appearance: none;
    border: 0;
    background: transparent;
  }
}

.container {
  max-width: 1500px;

  width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
}

form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: min(100%, 400px);
  border-bottom: 1px solid $dark;

  position: relative;
}

input {
  padding: 0.75rem 0;
  border: none;
  background: none;
  font-weight: 500;
  transition: border 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  border-radius: 0;
  width: 100%;
  font-size: 1.05rem;
  font-weight: bolder;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: $dark;
  }
}

@media (min-width: 675px) {
  .layout {
    display: flex;

    flex-wrap: nowrap;
    column-gap: 2rem;
  }

  .w-50 {
    width: 50%;
  }

  .w-25 {
    width: 25%;
  }
}

svg {
  margin: 0.5rem;
}

.c-2 {
  margin-top: 3.5rem;
}

.footer_copyright {
  color: $light;
}

// .about-div-main {
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   align-items: center;

//   justify-content: center;
//   padding: 3rem;
//   gap: 3rem;
//   h1 {
//     font-size: 2.5rem;
//     font-weight: bold;
//     text-align: center;
//     color: $accent-color;
//   }
//   .about-content {
//     width: 100%;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     gap: 3rem;
//     .about-text {
//       width: 70%;
//       text-align: justify;
//       font-size: 1.5rem;
//       color: #a0a0a0;
//     }
//     .about-image {
//       width: 100%;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       img {
//         width: 100%;
//         height: 100%;
//         object-fit: cover;
//         border-radius: 2rem;
//       }
//     }
//   }
//   @media screen and (max-width: 768px) {
//     padding: 1rem;
//     .about-content {
//       .about-text {
//         width: 100%;
//         text-align: center;
//       }
//       .about-image {
//         img {
//           width: 100%;
//           height: 100%;
//           object-fit: cover;
//           border-radius: 2rem;
//         }
//       }
//     }
//   }
// }

.about-div-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  margin-bottom: 6rem;
  .about_div_card1 {
    width: 90vw;
    height: 83vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5rem;

    .left {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 3rem;
      .about-text {
        width: 70%;
        text-align: justify;

        .title {
          font-size: 3rem;
          margin-bottom: 1rem;
          color: $accent-color;
        }

        p {
          font-size: 1.3rem;
          color: #727272;
        }
      }
    }

    .right {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 2rem;
      }
    }
  }
  .about_div_card2 {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    .top {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 3rem;
      .about-text {
        width: 70%;
        text-align: justify;

        .title {
          font-size: 3rem;
          color: $accent-color;
          margin-bottom: 1rem;
        }

        p {
          font-size: 1.5rem;
          color: #727272;
        }
      }
    }

    .bottom {
      display: flex;
      border-radius: 2rem;
      height: 70vh;
      overflow: hidden;
      width: 80%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .about_div_card1 {
      flex-direction: column;
      gap: 3rem;
      height: auto;
      padding: 1rem;
      .left {
        width: 100%;
        .about-text {
          width: 100%;
          text-align: justify;
          padding: 1rem;
        }
      }
      .right {
        width: 100%;
        height: 20rem;
        padding: 1rem;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 2rem;
        }
      }
    }
    .about_div_card2 {
      flex-direction: column;
      gap: 3rem;
      height: auto;
      padding: 1rem;
      .top {
        .about-text {
          width: 100%;
          text-align: justify;
          padding: 1rem;
        }
      }
      .bottom {
        width: 100%;
        padding: 1rem;
        height: 20rem;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .team_div_main {
      padding: 1rem;
      margin-top: 2rem;
      .cards {
        flex-direction: column;
        padding: 1rem;
        gap: 1rem;
        .card {
          align-items: center;
          background-color: rgb(235, 235, 235);
          .image {
            height: 70%;
          }
          .text {
            height: 30%;
          }
        }
      }
    }
  }
}

.team_div_main {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  gap: 1rem;
  .title {
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
  }

  .cards {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 2rem;
    gap: 3rem;

    .card {
      width: 100%;
      display: flex;
      padding: 1rem;
      flex-direction: column;
      align-items: center;
      border-radius: 3rem;
      height: 30rem;
      gap: 1rem;

      &.inverted {
        flex-direction: row-reverse;
      }

      .image {
        height: 70%;
        width: 100%;
        border-radius: 2.5rem;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &:hover {
          img {
            transform: scale(1.02);
            transition: all 0.5s ease-in-out;
          }
        }
      }

      .text {
        width: 100%;
        height: 30%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        gap: 1rem;

        h1 {
          font-size: 2rem;
          font-weight: bold;
        }

        p {
          font-size: 1.2rem;
          color: #a0a0a0;
        }
      }
    }
  }
}

.contact_div_main {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
  gap: 3rem;

  .get_in_touch_Form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    .get_in_touch_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2rem;
      width: 100%;
      padding: 1rem;

      .left {
        width: 70%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
          font-size: 3rem;
          font-weight: bold;
          text-align: center;
          margin-bottom: 4rem;
        }

        // .caption {
        //   font-size: 1.5rem;
        //   color: #727272;
        // }

        form {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          gap: 1.5rem;
          .input {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 1.5rem;
            input {
              width: 100%;
              padding: 1.5rem;
              background-color: #ddd7d7;
              border: none;
              border-radius: 1rem;
              font-weight: 500;
              transition: border 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
              width: 100%;
              font-size: 1.05rem;
              font-weight: bolder;
              &:focus {
                outline: none;
              }
            }
          }
          .message {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 1rem;
            textarea {
              width: 100%;
              padding: 1rem;
              background-color: #ddd7d7;
              border-radius: 1rem;
              font-weight: 500;
              border: none;
              transition: border 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
              width: 100%;
              font-size: 1.05rem;
              font-weight: bolder;
              &:focus {
                outline: none;
              }
            }
          }
          .submit {
            width: 20%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            align-self: flex-start;
            gap: 1rem;
            border: none;
            margin-bottom: 1rem;
            button {
              width: 100%;
              padding: 1rem;
              border: none;
              background: #000;
              border-radius: 1rem;
              outline: none;
              color: #fff;
              font-weight: 500;
              transition: 0.3s ease-in;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 1rem;
    .get_in_touch_Form {
      width: 100vw;

      .get_in_touch_container {
        width: 100%;
        padding: 0rem;

        .left {
          .title {
            font-size: 2rem;
          }
          form {
            .input {
              input {
                padding: 1rem;
              }
            }
            .message {
              textarea {
                padding: 1rem;
              }
            }
            .submit {
              width: 100%;
              button {
                padding: 1rem;
                width: 100%;
              }
            }
          }
        }
      }
    }

    .location_div_main {
      width: 90%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 50vh;
      border-radius: 2rem;
      overflow: hidden;

      iframe {
        width: 100%;
        height: 100%;
        border: none;
      }
    }

    .socials {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      h1 {
        font-size: 1rem;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
      }
      a {
        text-decoration: none;
        color: #000;
        font-size: 2rem;
        transition: 0.3s ease-in;
        &:hover {
          color: #585858;
        }
      }
    }
  }
}

.location_div_main {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50rem;
  border-radius: 2rem;
  overflow: hidden;

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
}

.socials {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  h1 {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
  }
  a {
    text-decoration: none;
    color: #000;
    font-size: 2rem;
    transition: 0.3s ease-in;
    &:hover {
      color: #585858;
    }
  }
}

.courses_div_main {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;

  .title {
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .cards {
    width: 80%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 3rem;

    a {
      text-decoration: none;
      color: #000;
    }

    .card {
      width: 100%;
      height: 28rem;
      background-color: #dbdada;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: all 0.5s ease-in-out;
      border-radius: 2rem;
      overflow: hidden;

      &:hover {
        transform: scale(1.02);
        box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
      }

      .image {
        width: 100%;
        height: 50%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .content {
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 0.5rem;
        padding: 1rem;

        .name {
          text-align: left;
          font-size: 1rem;
          padding: 0.5rem;
          background-color: #5c5c5c;
          color: #fff;
          border-radius: 0.5rem;
        }

        .title {
          font-size: 1.5rem;
          font-weight: bold;
          text-align: left;
          color: $accent-color;
        }
        .bottom {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0rem 0.5rem;
          justify-content: space-between;
          width: 100%;

          .price,
          .duration {
            display: flex;
            gap: 0.7rem;

            span {
              font-weight: 500;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0rem;

    .cards {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      padding-bottom: 2rem;

      .card {
        border-radius: 2rem;
        height: auto;
        display: flex;
        background-color: #ddd7d7;
        flex-direction: column;

        .image {
          width: 100%;
          height: 20rem;
        }

        .content {
          width: 100%;
          text-align: justify;
          .title {
            font-size: 2rem;
          }

          a {
            position: relative;
            bottom: 0rem;
            right: 0rem;
            opacity: 1;
          }
        }
      }
    }
  }
}

.course_detail_div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 3rem;
  gap: 3rem;

  .top {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 3rem;
  }

  .left {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    .image {
      width: 100%;
      height: 50vh;
      overflow: hidden;
      border-radius: 2rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: 2rem;
      button {
        padding: 1.4rem 3rem;
        border: none;
        background-color: $accent-color;
        color: #fff;
        font-weight: bold;
        border-radius: 1rem;
        transition: all 0.5s ease-in-out;
        text-transform: uppercase;
        cursor: pointer;
        &:hover {
          background-color: #585858;
        }
      }
    }
  }

  .right {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;

    .title {
      font-size: 3rem;
      font-weight: bold;
      color: $accent-color;
    }

    .description {
      font-size: 1.5rem;
      color: #727272;
      text-align: justify;
    }

    .details {
      margin-top: 2rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      ul {
        display: flex;
        flex-direction: column;
        list-style: none;
        gap: 1rem;

        li {
          .fa-solid {
            width: 1.5rem;
          }

          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          padding: 0.7rem;
          gap: 1rem;
          span {
            font-weight: 500;
          }
        }
      }
    }
  }

  .bottom {
    padding: 2rem;
    width: 93%;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border-radius: 2rem;
    background-color: #dbdada;
    gap: 0.5rem;

    .title {
      font-size: 2rem;
      text-transform: uppercase;
      font-weight: bold;
      color: $accent-color;
    }

    .text {
      font-size: 1.4rem;
      color: #727272;
      margin-bottom: 1rem;
    }

    .kit_details {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      list-style: none;
      li {
        font-size: 1.2rem;
        color: #727272;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 1rem;
    .top {
      flex-direction: column;
      gap: 2rem;
      width: 100%;

      .left {
        width: 100%;
        .image {
          height: 30vh;
        }
        .buttons {
          flex-direction: column;
          gap: 1rem;
          button {
            width: 100%;
          }
          .price {
            font-weight: 400;
            color: #000;
          }
        }
      }

      .right {
        width: 100%;

        .details {
          background-color: #dbdada;
          padding: 1rem;
          border-radius: 2rem;
          ul {
            li {
              span {
                font-size: 1.2rem;
              }
            }
          }
        }
      }
    }
    .bottom {
      width: 100%;
      padding: 2rem;

      .title {
        font-size: 1.8rem;
      }

      .text {
        text-align: justify;
      }
    }
  }
}

.services_div_main {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;

  .title {
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .cards {
    width: 80%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;

    a {
      text-decoration: none;
      color: #000;
    }

    .card {
      width: 100%;
      height: 29rem;
      background-color: #dbdada;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: all 0.5s ease-in-out;
      border-radius: 2rem;
      overflow: hidden;

      &:hover {
        transform: scale(1.02);
        box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
      }

      .image {
        width: 100%;
        height: 60%;
        overflow: hidden;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .link {
          position: absolute;
          top: 1rem;
          right: 1rem;
          padding: 1rem;
          background-color: #000;
          color: #fff;
          border-radius: 1rem;
          opacity: 0;
          transition: all 0.5s ease-in-out;

          &:hover {
            background-color: #252525;
          }
        }

        &:hover .link {
          opacity: 1;
        }
      }

      .content {
        width: 100%;
        height: 40%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 1rem;
        padding: 1rem;

        .name {
          text-align: left;
          font-size: 1rem;
          padding: 0.5rem;
          background-color: #5c5c5c;
          color: #fff;
          border-radius: 0.5rem;
        }

        .description {
          font-size: 1.2rem;
          color: #727272;
          text-align: start;
        }

        .title {
          font-size: 1.5rem;
          font-weight: bold;
          text-align: left;
          color: $accent-color;
          margin: 0;
        }
        .bottom {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0rem 0.5rem;
          justify-content: space-between;
          width: 100%;

          .price,
          .duration {
            display: flex;
            gap: 0.7rem;

            span {
              font-weight: 500;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0rem;

    .cards {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      padding-bottom: 2rem;

      .card {
        border-radius: 2rem;
        height: 28rem;
        display: flex;
        background-color: #ddd7d7;
        flex-direction: column;

        .image {
          width: 100%;
          height: 20rem;
        }

        .content {
          width: 100%;
          height: 50%;
          text-align: justify;
          padding: 1rem;

          .title {
            font-size: 2rem;
          }

          a {
            position: relative;
            bottom: 0rem;
            right: 0rem;
            opacity: 1;
          }
        }
      }
    }
  }
}

.get_course_div_main {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;

  .title {
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    form {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      border: none;
      background-color: #ececec;
      padding: 2rem;
      border-radius: 2rem;

      label {
        font-size: 1.5rem;
        text-align: left;
        margin-bottom: 1rem;
      }

      button {
        padding: 1.4rem 3rem;
        border: none;
        background-color: $accent-color;
        color: #fff;
        font-weight: bold;
        border-radius: 1rem;
        transition: all 0.5s ease-in-out;
        text-transform: uppercase;
        cursor: pointer;
        &:hover {
          background-color: #585858;
        }
      }

      input,
      select,
      textarea {
        width: 100%;
        padding: 1.5rem;
        background-color: #f1f1f1;
        border: none;
        border-radius: 1rem;
        font-weight: 500;
        transition: border 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
        width: 100%;
        font-size: 1.05rem;
        font-weight: bolder;
        margin-bottom: 2rem;
        &:focus {
          outline: none;
        }
      }

      input,
      textarea {
        background-color: transparent;
        border-radius: 0;
        border-bottom: 2px solid rgb(54, 54, 54);
        transition: 0.5s ease-in-out;

        &:focus {
          border-bottom: 2px solid $accent-color;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 2rem;
    .form {
      form {
        width: 100%;
        padding: 1rem;
        label {
          font-size: 1.5rem;
        }
        input,
        select,
        textarea {
          padding: 1rem;
          font-size: 1.05rem;
          margin-bottom: 1rem;
        }
      }
    }
  }
}
